import { fontWeight } from "../../../../utils/style-utils"
import { SlatePlugins } from "@udecode/slate-plugins"
import React, { useContext } from "react"
import styled from "styled-components"
import { options, components, editableProps, getMessageBody, plugins } from "../../../../utils/slate-plugins-utils"
import { newPalette, buildClassNames as cx, fontFamily, gridBreakPoints } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { IMessageFormContext, MessageFormContext } from "./Context/MessageFormContext"
import { useMutation } from "@apollo/client/react/hooks"
import {
  ToggleSilenceUserForPostingMutation,
  ToggleSilenceUserForPostingMutationVariables,
} from "../../../../../__generated__/ToggleSilenceUserForPostingMutation"
import { TOGGLE_SILENCE_USER_FOR_POSTING } from "../../../queries"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import QuoteSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Quote"
import DeleteSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Delete"
import MuteSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Mute"
import UnmuteSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Unmute"
import Avatar from "@cbs-sports/sports-shared-client/build/cjs/components/Avatar"
import { getDateDiff } from "../../../../utils/data-utils"
import { BaseSlate } from "./MessageEditor"
import { PoolMessageBoardQuery_pool_messages_edges_node } from "../../../../../__generated__/PoolMessageBoardQuery"
import PoolDataContext, { PoolDataContextType } from "../../../../Contexts/PoolDataContext"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { trackActionByKey } from "./message-board-tracking"
const { breakpoints } = gridBreakPoints

const BodyWrapper = styled(BaseSlate)`
  font-weight: ${fontWeight.regular};
  background: ${newPalette.white};
  border: 1px solid ${newPalette.gray90};
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  &.upsert-message {
    border-color: ${newPalette.red};
  }

  & > .message-tile {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
    position: relative;

    & > .message-info {
      display: flex;
      flex-direction: column;
      margin-left: 0.75rem;
      justify-content: center;

      & > .from {
        font-family: ${fontFamily.base};
        font-size: 14px;
        line-height: 1.25rem;
        letter-spacing: -0.1px;
        text-align: left;
        color: ${newPalette.gray20};
        font-weight: ${fontWeight.bold};
      }
      & > .sent-at {
        font-family: ${fontFamily.base};
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: -0.1px;
        text-align: left;
        color: ${newPalette.gray40};
        font-weight: ${fontWeight.regular};
      }
    }
  }
  & > .message-tile.is-new:before {
    content: "";
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    left: -1rem;
    top: 1rem;
    background-color: ${newPalette.lightBlue2};
  }

  & > .message-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1.5rem;

    & > .admin-actions {
      display: flex;
      & > button:last-child {
        margin-left: 1rem;
      }
    }
  }
  & > .message-actions.with-actions {
    justify-content: space-between;
  }
  & > .message-actions.without-admin-actions {
    justify-content: space-between;
  }

  @media (max-width: ${breakpoints.sm - 1}px) {
    border-radius: 0;
  }
`

interface MessageBodyProps {
  message: PoolMessageBoardQuery_pool_messages_edges_node
  showActions?: boolean
}

const MessageBody = ({ message, showActions = true }: MessageBodyProps) => {
  const { from, deleteInformation, rootMessageId, sentAt } = message
  const { userId, avatarUrl, canPostMessages } = from || {}
  const { firstName, lastName } = from?.userInfo || {}
  const messageFormContext: IMessageFormContext = useContext(MessageFormContext)
  const poolData: PoolDataContextType = useContext(PoolDataContext)
  const { showMessageForm, refreshQuery, currentUser, currentPlayer } = messageFormContext
  const { poolId, hasManagerRole, deviceType } = poolData || emptyObject
  const body = getMessageBody(message, hasManagerRole || currentUser?.isCbsAdmin)

  const [toggleSilenceUserForPostingMutation] = useMutation<ToggleSilenceUserForPostingMutation, ToggleSilenceUserForPostingMutationVariables>(
    TOGGLE_SILENCE_USER_FOR_POSTING,
  )

  const onQuoting = () => {
    showMessageForm(true, Object.assign({}, message, { id: "" }))
    trackActionByKey("quote-post")
  }

  const removeMessage = () => {
    messageFormContext.showDeleteMessageForm(message)
    trackActionByKey("delete-topic-in-thread")
  }

  const toggleBlockAuthor = () => {
    trackActionByKey(canPostMessages ? "mute-user" : "unmute-user")
    const variables: ToggleSilenceUserForPostingMutationVariables = {
      userId: userId ?? "",
      poolId,
    }
    toggleSilenceUserForPostingMutation({ variables })
      .then(refreshQuery)
      .catch((err) => {
        window.SH_ERROR_NOTIFY(err)
      })
  }

  const isManagerOrAdmin = currentUser?.isCbsAdmin || hasManagerRole
  const showAdminActions = showActions && isManagerOrAdmin //&& userId !== picksDbId
  const showMuteOption = currentUser?.picksDbId !== message?.from?.userId
  const showQuote = currentPlayer?.canPostMessages && !deleteInformation
  const showDelete = !deleteInformation
  const fullName = `${firstName} ${lastName}`
  return (
    <BodyWrapper
      className={cx({
        "upsert-message": deleteInformation && isManagerOrAdmin,
      })}
    >
      <div className="message-tile">
        <Avatar size={48} alt="" src={avatarUrl || undefined} name={fullName} />
        <div className="message-info">
          <span className="from">{fullName}</span>
          <span className="sent-at">{getDateDiff(sentAt)}</span>
        </div>
      </div>
      <SlatePlugins
        id={message?.id}
        plugins={plugins}
        components={components}
        options={options}
        editableProps={{ ...editableProps, readOnly: true }}
        value={body}
        onChange={(c) => console.log(c)}
      />
      <div
        className={cx({
          "message-actions": true,
          "with-actions": showQuote && showAdminActions,
          "without-admin-actions": !showAdminActions,
        })}
      >
        {showQuote && deviceType !== "handheld" && (
          <Button variant="secondary" onClick={() => onQuoting()}>
            <ListItemIcon size="md" alignment="left">
              <QuoteSvg />
            </ListItemIcon>
            Quote
          </Button>
        )}
        {showAdminActions && deviceType !== "handheld" && (
          <div className="admin-actions">
            {showMuteOption && (
              <Button variant="secondary" onClick={toggleBlockAuthor}>
                <ListItemIcon size="md" alignment="left">
                  {canPostMessages ? <MuteSvg /> : <UnmuteSvg />}
                </ListItemIcon>
                {canPostMessages ? "Mute User" : "Unmute User"}
              </Button>
            )}

            {showDelete && (
              <Button variant="secondary" onClick={removeMessage} disabled={!rootMessageId}>
                <ListItemIcon size="md" alignment="left">
                  <DeleteSvg />
                </ListItemIcon>
                Delete
              </Button>
            )}
          </div>
        )}

        {showQuote && deviceType === "handheld" && (
          <Button variant="secondary" onClick={() => onQuoting()}>
            <ListItemIcon size="md">
              <QuoteSvg />
            </ListItemIcon>
          </Button>
        )}
        {showAdminActions && deviceType === "handheld" && (
          <>
            {showMuteOption && (
              <Button variant="secondary" onClick={toggleBlockAuthor}>
                <ListItemIcon size="md" alignment="left">
                  {canPostMessages ? <MuteSvg /> : <UnmuteSvg />}
                </ListItemIcon>
                {canPostMessages ? "Mute User" : "Unmute User"}
              </Button>
            )}
            {showDelete && (
              <Button variant="secondary" onClick={removeMessage} disabled={!rootMessageId}>
                <ListItemIcon size="md">
                  <DeleteSvg />
                </ListItemIcon>
              </Button>
            )}
          </>
        )}
      </div>
    </BodyWrapper>
  )
}
export default MessageBody
