import React from "react"
import { TPoolRouteProps } from "../../../../../routes.d"
import { StandardPoolPageContainer } from "../../components/PoolPage"
import MultipleEntriesMessageBoard from "../MultipleEntriesArea/MultipleEntriesMessageBoard"

function PoolMessageBoardWrapper(props: TPoolRouteProps) {
  return (
    <StandardPoolPageContainer {...props}>
      <MultipleEntriesMessageBoard {...props} />
    </StandardPoolPageContainer>
  )
}

export default PoolMessageBoardWrapper
