import * as React from "react"
import PageVisibility, { States, TState } from "../utils/page-visibility"

interface IQuery {
  data?: null | any
  refetch: () => void
  networkStatus: number
}
interface IApiRefresherProps {
  refetchAfterMs: number
  query: IQuery
  networkStatus: number
  onMount?: () => any
}

class ApiRefresher extends React.PureComponent<IApiRefresherProps> {
  // Instance
  public timeoutId: number | undefined
  public lastUpdatedAt: number | undefined
  public lastCheckScheduledAt: number | undefined
  public isOnline = true

  public componentDidMount() {
    this.processScheduling()
    PageVisibility.subscribe(this.onVisibilityChange)
    if (typeof window !== "undefined") {
      window.addEventListener("online", this.onOnlineChange)
      window.addEventListener("offline", this.onOnlineChange)
    }
    if (this.props.onMount) {
      this.props.onMount()
    }
  }

  public log(arg: any, arg1?: any) {
    if (typeof window !== "undefined" && window.hasOwnProperty("QQ")) {
      console.dir(arg)
      if (arg1) {
        console.dir(arg1)
      }
    }
  }

  public processScheduling() {
    // https://github.com/apollographql/apollo-client/blob/master/src/core/networkStatus.ts
    const { query } = this.props
    const queryLoaded = !!query && query.networkStatus === 7
    if (queryLoaded && !this.timeoutId) {
      this.log(`scheduling`, query)
      this.lastCheckScheduledAt = Date.now()
      this.timeoutId = window.setTimeout(this.process, this.props.refetchAfterMs)
    } else if (!queryLoaded && !!this.timeoutId) {
      this.log(`unscheduling`, query)
      this.clearTimeout()
    }
  }

  public componentDidUpdate(prevProps: IApiRefresherProps) {
    this.processScheduling()
    const newMs = this.props.refetchAfterMs
    const canSchedule = this.canSchedule()
    this.log(`componentDidUpdate, ${newMs} !== ${prevProps.refetchAfterMs} (${canSchedule})`)
    if (canSchedule && newMs !== prevProps.refetchAfterMs) {
      this.clearTimeout()
      const nowAt = Date.now()
      const inMs = newMs - (nowAt - (this.lastCheckScheduledAt || nowAt))
      this.schedule(inMs)
    }
  }

  public canSchedule() {
    return !!this.timeoutId
  }

  public componentWillUnmount() {
    this.log(`componentWillUnmount`)
    this.clearTimeout()
    PageVisibility.unsubscribe(this.onVisibilityChange)
    if (typeof window !== "undefined") {
      window.removeEventListener("online", this.onOnlineChange)
      window.removeEventListener("offline", this.onOnlineChange)
    }
  }

  public onOnlineChange = () => {
    const isOnline = navigator.onLine
    this.log(`onOnlineChange: ${isOnline}`)
    this.processScheduling()
    // Only immeidiate process if we have passed the tick (indicated by the this.isOnline === false trip)
    if (isOnline && !this.isOnline && this.canSchedule()) {
      this.process()
    }
  }

  public process = () => {
    this.log(`process...`)
    this.clearTimeout()
    this.isOnline = typeof navigator.onLine === "boolean" ? navigator.onLine : true
    if (this.isOnline) {
      if (this.props.query && this.props.query.refetch) {
        this.props.query.refetch()
      }
      // This should be done by networkStatus changes
      // this.schedule();
      // this.processScheduling();
    } else {
      console.warn(`Skipping due to offline status, manually rechecking in 3 sec`)
      this.schedule(3000)
    }
  }

  public schedule = (inMs?: number) => {
    this.lastCheckScheduledAt = Date.now()
    this.timeoutId = window.setTimeout(this.process, inMs || this.props.refetchAfterMs)
  }
  public clearTimeout = () => {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId)
      this.timeoutId = undefined
    }
  }
  public onVisibilityChange = (newState: TState) => {
    this.log(`onVisibilityChange: ${newState}`)
    this.processScheduling()
    if (newState === States.visible && this.canSchedule()) {
      this.clearTimeout()
      const diff = this.lastCheckScheduledAt ? Date.now() - this.lastCheckScheduledAt : 0
      this.log(`onVisibilityChange: ${diff}`)
      this.timeoutId = window.setTimeout(this.process, Math.max(this.props.refetchAfterMs - diff, 0))
    }
    if (newState === States.hidden) {
      this.clearTimeout()
    }
  }

  public render() {
    return this.props.children || null
  }
}

export default ApiRefresher
