/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-types */
import {
  HeadingToolbar,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_UL,
  ELEMENT_OL,
  SlatePlugins,
  TNode,
  ToolbarList,
  ToolbarMark,
  useStoreEditor,
  serializeHTMLFromNodes,
} from "@udecode/slate-plugins"
import { Node } from "slate"
import React from "react"
import styled from "styled-components"
import {
  fontFamily,
  fontWeight,
  newPalette,
  zIndexes,
  pxToRem,
  breakpoints,
  TDeviceType,
} from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { options, components, editableProps, plugins, preserveClassNames } from "../../../../utils/slate-plugins-utils"
import BoldSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Bold"
import UnderlineSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Underline"
import StrikethroughSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Strikethrough"
import BulletListSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/BulletList"
import NumberedListSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/NumberedList"
import ItalicSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Italic"
import DeleteSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Delete"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import SendSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Send"
import { PoolMessageBoardQuery_pool_messages_edges_node } from "../../../../../__generated__/PoolMessageBoardQuery"

const activeColor = "#E5F4FF"
export const BaseSlate = styled.div`
  font-family: ${fontFamily.base};
  font-size: 14px;
  line-height: 1.25rem;
  letter-spacing: -0.1px;
  text-align: left;
  & * {
    -webkit-user-select: text;
    user-select: text;
  }
  * blockquote.cbs-mb-quote {
    position: relative !important;
    margin-bottom: 1.5rem;

    & > p:nth-child(2) {
      font-style: italic;
    }
  }

  blockquote.cbs-mb-quote:before {
    content: "";
    position: absolute !important;
    width: 0.25rem;
    position: static;
    height: 100%;
    left: 0px;
    top: 0px;
    background: ${newPalette.lightBlue2};
    border-radius: 0 0.25rem 0.25rem 0;
  }

  em.cbs-mb-italic-text {
    font-style: italic;
  }
  strong.cbs-mb-bold-text {
    font-weight: ${fontWeight.bold};
    font-size: 14px;
    line-height: 1rem;
    letter-spacing: -0.1px;
    text-align: left;
  }
  sub.cbs-mb-title-bold-text {
    color: ${newPalette.lightBlue2};
    vertical-align: baseline;
    font-weight: ${fontWeight.semiBold};
  }
  ol.cbs-mb-sorted-list,
  ul.cbs-mb-unsorted-list {
    > li {
      list-style: unset;
    }
  }
  p.cbs-mb-p {
    white-space: pre-wrap !important;
  }
`
const Body = styled(BaseSlate)`
  display: flex;
  flex-direction: column-reverse;
  margin: 0;
  border-radius: 0 0 0.5rem 0.5rem;
  height: 24rem;
  box-sizing: border-box;

  div.tool-bar-options {
    margin: 0;
    padding: 0;
  }

  button.send-btn {
    margin-right: 2rem;
  }
  button.send-btn:after {
    content: "";
    position: absolute;
    width: 1px;
    background-color: ${newPalette.gray90};
    height: 100%;
    right: -1rem;
  }
  button.discard-btn {
    position: absolute;
    right: 1rem;
  }

  .toolbar-item.slate-ToolbarButton-active {
    background-color: ${activeColor} !important;
    color: ${newPalette.lightBlue2} !important;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 0.125rem;

    & > * {
      color: ${newPalette.lightBlue2};
    }
  }

  @media (hover: hover) {
    .toolbar-item.slate-ToolbarButton-active {
      & > .icon-wrap {
        background-color: ${activeColor} !important;
      }
      & > * {
        :hover:before {
          display: none;
        }
      }
    }
  }

  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    min-width: 20rem;
    width: calc(100vw - 3rem);

    button.discard-btn {
      span:first-of-type {
        justify-content: center;
      }
      .btn-text {
        display: none;
      }
    }
  }
`
const ActionIconWrap = styled.div`
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 0.125rem;
  color: ${newPalette.gray50};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  @media (hover: hover) {
    :hover:not(:active) {
      background-color: ${newPalette.lightestBlue};
      color: ${newPalette.lightBlue2};
    }
    :hover:not(:active):before {
      position: absolute;
      content: attr(data-name);
      padding: 0.25rem 0.5rem;
      font-family: ${fontFamily.base};
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0px;
      text-align: left;
      white-space: nowrap;
      background: ${newPalette.darkBlue2};
      border-radius: 0.25rem;
      top: -0.25rem;
      color: ${newPalette.white};
      z-index: ${zIndexes.ui1};
    }
  }
`

const ToolbarMarkStyles = {
  root: {
    marginRight: "0.25rem",
  },
}

interface MessageEditorProps {
  quote?: PoolMessageBoardQuery_pool_messages_edges_node
  onBodyChange?: (newValue: TNode<{}>[], htmlNewValue: string, isEmpty?: boolean, textSize?: number) => void
  onConfirm: () => void
  onCancel: () => void
  id?: string
  defaultValue: any[]
  loading?: boolean
  isValid: boolean
  deviceType?: TDeviceType
}

const editorStyles = {
  height: "100%",
  backgroundColor: newPalette.white,
  overflow: "auto",
  padding: "1rem",
}

const toolBarStyles = {
  root: {
    margin: 0,
    padding: "1rem",
    borderTop: `1px solid ${newPalette.gray90}`,
    borderBottom: 0,
  },
}

const ActionIcon = ({ children, name }: { children: React.ReactNode; name: string }) => {
  return (
    <ActionIconWrap className="icon-wrap" data-name={name}>
      {children}
    </ActionIconWrap>
  )
}
const MessageEditor = ({ onBodyChange, id, defaultValue, loading, isValid, onConfirm, onCancel, deviceType }: MessageEditorProps) => {
  const storeEditor = useStoreEditor(id)

  const serialize = (nodes) => {
    return nodes.map((n) => Node.string(n)).join("")
  }

  const onSlateChange = (newValue: TNode<{}>[]) => {
    if (onBodyChange) {
      if (storeEditor) {
        const storeCopy = Object.assign({}, storeEditor, { children: [...storeEditor.children.filter((x: any) => x.type !== ELEMENT_BLOCKQUOTE)] })
        const isEmpty = Node.string(storeCopy).trim() === ""
        const textSize = serialize(newValue).length
        const htmlResult = serializeHTMLFromNodes(storeEditor, {
          nodes: newValue,
          plugins,
          preserveClassNames,
          stripDataAttributes: true,
        })
        onBodyChange(newValue, htmlResult, isEmpty, textSize)
      } else {
        onBodyChange(newValue, "", true, 0)
      }
    }
  }

  return (
    <Body>
      <SlatePlugins
        id={id}
        plugins={plugins}
        options={options}
        components={components}
        editableProps={{ ...editableProps, style: editorStyles }}
        value={defaultValue}
        onChange={onSlateChange}
      >
        <HeadingToolbar styles={toolBarStyles} className="sp-heading-toolbar">
          <Button withLoading loading={loading} disabled={!isValid} onClick={onConfirm} className="send-btn">
            <ListItemIcon size="md" alignment="left">
              <SendSvg />
            </ListItemIcon>
            Send
          </Button>
          <ToolbarMark
            styles={ToolbarMarkStyles}
            type={MARK_BOLD}
            className="toolbar-item"
            icon={
              <ActionIcon name="Bold">
                <BoldSvg />
              </ActionIcon>
            }
          />
          <ToolbarMark
            styles={ToolbarMarkStyles}
            type={MARK_ITALIC}
            className="toolbar-item"
            icon={
              <ActionIcon name="Italic">
                <ItalicSvg />
              </ActionIcon>
            }
          />
          <ToolbarMark
            type={MARK_UNDERLINE}
            styles={ToolbarMarkStyles}
            className="toolbar-item"
            icon={
              <ActionIcon name="Underline">
                <UnderlineSvg />
              </ActionIcon>
            }
          />
          {deviceType !== "handheld" && (
            <>
              <ToolbarMark
                className="toolbar-item"
                styles={ToolbarMarkStyles}
                type={MARK_STRIKETHROUGH}
                icon={
                  <ActionIcon name="Strikethrough">
                    <StrikethroughSvg />
                  </ActionIcon>
                }
              />
              <ToolbarList
                className="toolbar-item"
                styles={ToolbarMarkStyles}
                type={options[ELEMENT_OL].type}
                icon={
                  <ActionIcon name="Numbered list">
                    <NumberedListSvg />
                  </ActionIcon>
                }
              />
              <ToolbarList
                className="toolbar-item"
                styles={ToolbarMarkStyles}
                type={options[ELEMENT_UL].type}
                icon={
                  <ActionIcon name="Bulleted list">
                    <BulletListSvg />
                  </ActionIcon>
                }
              />
            </>
          )}
          <Button onClick={onCancel} variant="secondary" className="discard-btn" aria-label="discard">
            <ListItemIcon size="md" alignment="left">
              <DeleteSvg />
            </ListItemIcon>
            <span className="btn-text">Discard</span>
          </Button>
        </HeadingToolbar>
      </SlatePlugins>
    </Body>
  )
}

export default MessageEditor
