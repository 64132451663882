import { useQuery } from "@apollo/client"
import React, { useCallback } from "react"
import { Redirect } from "react-router-dom"
import styled from "styled-components/macro"
import { PoolMessageBoardQuery, PoolMessageBoardQueryVariables } from "../../../../../__generated__/PoolMessageBoardQuery"
import { newPalette, pxToRem, buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { TPoolRouteProps } from "../../../../../routes.d"
import ApiRefresher from "../../../../Base/ApiRefresher"
import LoadingView from "../../../../components/LoadingView"
import useControls, { paginationParam } from "../../../../hooks/useControls"
import { edgeToNode } from "../../../../utils/misc-utils"
import { getStringParam, setParam, withoutDomain } from "../../../../utils/url-utils"
import { POOL_MESSAGE_BOARD_QUERY } from "../../../queries"
import MessageFormProvider from "../MessageBoard/Context/MessageFormContext"
import MessagesContainer from "../MessageBoard/MessagesContainer"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import PaginationNew from "../../components/PaginationNew"
import AnalyticScreen from "../../../../components/AnalyticsScreen"
import { PoolSettingsTypesEnum } from "../../../../../common/enums"

const oneMinute = 60 * 1000
const emptyArray = [] as never[]

const StyledMessagesContainer = styled.div`
  @media (min-width: ${pxToRem(800)}) {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
`
const MessagesWrapper = styled.div`
  overflow: hidden;

  &.is-message-board {
    background-color: ${newPalette.white};
    border-radius: 0 0 0.5rem 0.5rem;
    border: 1px solid ${newPalette.gray90};
    border-top: 0;
  }
`
const paginationStyles: React.CSSProperties = { marginTop: "1rem" }

const MultipleEntriesMessageBoard = (props: TPoolRouteProps) => {
  const { poolData, location, history } = props
  const { currentUser, poolId, poolDetail, deviceType } = poolData
  const currentSearch = location.search
  const rootMessageId = getStringParam("rootMessageId", currentSearch)
  const { first, after, goToPage } = useControls(props)

  const onNewMessageCallBack = useCallback(
    (isReply?: boolean) => {
      if (!isReply) {
        history.replace({
          search: "",
        })
      }
    },
    [history],
  )

  const skipRootMessage = !rootMessageId
  const variables: PoolMessageBoardQueryVariables = {
    poolId,
    first,
    rootMessageId,
    after,
    skipRootMessage,
  }

  const poolMessageBoardQuery = useQuery<PoolMessageBoardQuery, PoolMessageBoardQueryVariables>(POOL_MESSAGE_BOARD_QUERY, {
    skip: !poolId || !currentUser,
    variables,
    fetchPolicy: "network-only",
  })
  const gotoThread = (key, value) => {
    let search = setParam(currentSearch, key, value)
    // start from first page
    search = setParam(search, paginationParam, "")
    const newLocation = Object.assign({}, props.location, { search })
    props.history.push(newLocation)
  }

  const includeMessageBoard =
    poolDetail && poolDetail.poolSettings?.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS && poolDetail.poolSettings?.includeMessageBoard

  if (poolDetail && !includeMessageBoard) {
    return <Redirect to={`${withoutDomain(poolDetail.url)}/standings`} />
  }
  // const entry = poolMessageBoardQuery.data?.entry
  // const entries = (poolMessageBoardQuery.data?.entry.pool.entries.edges || emptyArray).map(edgeToNode)
  const rootMessage = poolMessageBoardQuery.data?.message
  const messages = (poolMessageBoardQuery.data?.pool.messages.edges || emptyArray).map(edgeToNode)
  const total = poolMessageBoardQuery.data?.pool.messages.pageInfo?.totalCount || 0
  const isLoading = !!poolMessageBoardQuery && poolMessageBoardQuery.networkStatus < 3
  const lastMessageSentRecently = (messages.length && messages[messages.length - 1].sentAt * 1000 + 15 * oneMinute > Date.now()) || false
  const onMount = undefined
  const pageInfo = poolMessageBoardQuery.data?.pool.messages.pageInfo || emptyObject
  return (
    <>
      <AnalyticScreen feature="brackets" subfeature={`message-board`} title={`Message Board`} deviceType={deviceType} />
      <StyledMessagesContainer>
        <MessageFormProvider
          upsertPoolMessageMutationVariables={{ ...variables, body: "", htmlBody: "" }}
          apiRefresherFn={poolMessageBoardQuery.refetch}
          currentUser={currentUser}
          rootMessage={rootMessage}
          newMessageCallBack={onNewMessageCallBack}
        >
          <ApiRefresher
            onMount={onMount}
            refetchAfterMs={1000 * (lastMessageSentRecently ? 30 : 500)}
            query={poolMessageBoardQuery}
            networkStatus={poolMessageBoardQuery.networkStatus}
          />
          <MessagesWrapper
            className={cx({
              "is-message-board": !rootMessageId,
            })}
          >
            {(isLoading && <LoadingView />) || (
              <MessagesContainer
                mkey={`${messages.map((m) => m.id).join(",")}`}
                topicsUrl={props.match.url}
                totalCount={total}
                messages={messages}
                isThreadDetails={!!rootMessageId}
                goToThread={gotoThread}
              />
            )}
          </MessagesWrapper>
          <PaginationNew {...pageInfo} hasPreviousPage={!!after} goToPage={goToPage} isLoading={isLoading} stylesProps={paginationStyles} />
        </MessageFormProvider>
      </StyledMessagesContainer>
    </>
  )
}

export default MultipleEntriesMessageBoard
