export type TState = "visible" | "hidden"

export type Cb = (State: TState) => void

export const States = {
  prerender: "prerender" as TState,
  visible: "visible" as TState,
  hidden: "hidden" as TState,
}

class PageVisibility {
  public States = States
  public state: TState = States.visible
  public callbacks: Cb[] = []

  public subscribe(cb: Cb) {
    if (!this.callbacks.length) {
      window.addEventListener("beforeunload", this.onBackground, false)
      window.addEventListener("pagehide", this.onBackground, false)
      window.addEventListener("pageshow", this.onForeground, false)
      if (document.visibilityState) {
        document.addEventListener("visibilitychange", this.onVisibilityChange, false)
      }
    }
    this.callbacks.push(cb)
  }
  public unsubscribe(cb: Cb) {
    const i = this.callbacks.indexOf(cb)
    if (i < 0) {
      throw new Error(`unknown cb!`)
    } else {
      this.callbacks.splice(i, 1)
      if (!this.callbacks.length) {
        window.removeEventListener("beforeunload", this.onBackground)
        window.removeEventListener("pagehide", this.onBackground)
        window.removeEventListener("pageshow", this.onForeground)
        if (document.visibilityState) {
          document.removeEventListener("visibilitychange", this.onVisibilityChange)
        }
      }
    }
  }

  private onBackground = (event: any) => {
    this.processVisibilityChange("visible", event)
  }

  private onForeground = (event: any) => {
    this.processVisibilityChange("visible", event)
  }

  private onVisibilityChange = (event: any) => {
    this.processVisibilityChange(document.visibilityState, event)
  }

  private processVisibilityChange = (newState: TState | "prerender", _event: any) => {
    // debug("VisibilityState: " + VisibilityState + ' -> ' + VisibilityStates[newState], _event)
    // ignore prerender
    if (newState === this.States.prerender) {
      return
    }
    // ignore unless changed
    if (this.state === this.States[newState]) {
      return
    }
    // Notify of change
    this.state = this.States[newState]
    this.callbacks.forEach((cb) => cb(this.state))
  }
}

const SingletonPageVisiblity = new PageVisibility()

export default SingletonPageVisiblity
