/* eslint-disable @typescript-eslint/ban-types */
import {
  AutoformatRule,
  createSlatePluginsOptions,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_LI,
  ELEMENT_OL,
  ELEMENT_UL,
  ExitBreakPluginOptions,
  KEYS_HEADING,
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  SoftBreakPluginOptions,
  SPEditor,
  toggleList,
  unwrapList,
  WithAutoformatOptions,
  TNode,
  ELEMENT_PARAGRAPH,
  SlatePluginsOptions,
  BlockquoteElement,
  StyledElement,
  LinkElement,
  StyledLeaf,
  MARK_UNDERLINE,
  ELEMENT_LINK,
  MARK_SUBSCRIPT,
  createAutoformatPlugin,
  createBlockquotePlugin,
  createBoldPlugin,
  createCodeBlockPlugin,
  createDeserializeHTMLPlugin,
  createExitBreakPlugin,
  createHistoryPlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createParagraphPlugin,
  createReactPlugin,
  createSoftBreakPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createTrailingBlockPlugin,
  createUnderlinePlugin,
  SlatePlugin,
  createSuperscriptPlugin,
  MARK_SUPERSCRIPT,
} from "@udecode/slate-plugins"
import { withProps } from "@udecode/slate-plugins-common"
import { EditableProps } from "slate-react/dist/components/editable"
import { ReactNode } from "react"
import { v4 as uuidv4 } from "uuid"
import { newPalette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { PoolMessageBoardQuery_pool_messages_edges_node } from "../../__generated__/PoolMessageBoardQuery"

const SLATE_CLASSES = {
  CBS_P: "cbs-mb-p",
  CBS_QUOTE: "cbs-mb-quote",
  CBS_UL: "cbs-mb-unsorted-list",
  CBS_OL: "cbs-mb-sorted-list",
  CBS_TITLE_BOLD: "cbs-mb-title-bold-text",
  CBS_BOLD: "cbs-mb-bold-text",
  CBS_ITALIC: "cbs-mb-italic-text",
}

const ALLOWED_TYPES = [
  ELEMENT_BLOCKQUOTE,
  ELEMENT_LI,
  ELEMENT_OL,
  ELEMENT_UL,
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  ELEMENT_PARAGRAPH,
  ELEMENT_LINK,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
]

const optionsFilter = (): SlatePluginsOptions => {
  const defaultOptions = createSlatePluginsOptions()
  const newOptions: SlatePluginsOptions = {}
  Object.keys(defaultOptions).forEach((x) => {
    if (ALLOWED_TYPES.includes(x)) {
      newOptions[x] = defaultOptions[x]
    }
  })
  return newOptions
}
const createSlatePluginsComponents = <T extends string = string>() => {
  const components = {
    [ELEMENT_BLOCKQUOTE]: withProps(BlockquoteElement, {
      nodeProps: {
        contentEditable: false,
      },
      styles: {
        root: {
          backgroundColor: newPalette.gray97,
          borderRadius: "0px 4px 4px 0px",
          border: 0,
          margin: 0,
          padding: "0.5rem 1rem",
          color: newPalette.gray20,
        },
      },
      className: SLATE_CLASSES.CBS_QUOTE,
    }),
    [ELEMENT_LI]: withProps(StyledElement, { as: "li" }),
    [ELEMENT_LINK]: LinkElement,
    [ELEMENT_UL]: withProps(StyledElement, {
      as: "ul",
      styles: {
        root: {
          backgroundColor: "transparent",
          color: newPalette.gray20,
          listStyleType: "square !important",
          paddingLeft: "2.5rem",
        },
      },
      className: SLATE_CLASSES.CBS_UL,
    }),
    [ELEMENT_OL]: withProps(StyledElement, {
      as: "ol",
      styles: {
        root: {
          backgroundColor: "transparent",
          color: newPalette.gray20,
          listStyleType: "decimal !important",
          paddingLeft: "2.5rem",
        },
      },
      className: SLATE_CLASSES.CBS_OL,
    }),
    [ELEMENT_PARAGRAPH]: withProps(StyledElement, { as: "p", className: SLATE_CLASSES.CBS_P }),
    [MARK_SUBSCRIPT]: withProps(StyledLeaf, { as: "sub", className: SLATE_CLASSES.CBS_TITLE_BOLD }),
    [MARK_SUPERSCRIPT]: withProps(StyledLeaf, {
      as: "sup",
      styles: {
        root: {
          backgroundColor: "transparent",
          color: newPalette.red,
          fontWeight: 600,
          fontStyle: "italic",
        },
      },
    }),
    [MARK_BOLD]: withProps(StyledLeaf, {
      as: "strong",
      className: SLATE_CLASSES.CBS_BOLD,
    }),
    [MARK_ITALIC]: withProps(StyledLeaf, { as: "em", className: SLATE_CLASSES.CBS_ITALIC }),
    [MARK_STRIKETHROUGH]: withProps(StyledLeaf, { as: "s" }),
    [MARK_UNDERLINE]: withProps(StyledLeaf, { as: "u" }),
  }

  return components as Record<T, ReactNode>
}

export const options = optionsFilter()
export const components = createSlatePluginsComponents()

const optionsSoftBreakPlugin: SoftBreakPluginOptions = {
  rules: [
    { hotkey: "shift+enter" },
    {
      hotkey: "enter",
      query: {
        allow: [options[ELEMENT_BLOCKQUOTE].type],
      },
    },
  ],
}

const optionsExitBreakPlugin: ExitBreakPluginOptions = {
  rules: [
    {
      hotkey: "mod+enter",
    },
    {
      hotkey: "mod+shift+enter",
      before: true,
    },
    {
      hotkey: "enter",
      query: {
        start: true,
        end: true,
        allow: KEYS_HEADING,
      },
    },
  ],
}

const preFormat: AutoformatRule["preFormat"] = (editor) => unwrapList(editor as SPEditor)
const optionsAutoformat: WithAutoformatOptions = {
  rules: [
    {
      type: options[ELEMENT_LI].type,
      markup: ["*", "-"],
      preFormat,
      format: (editor) => {
        toggleList(editor as SPEditor, { type: options[ELEMENT_UL].type })
      },
    },
    {
      type: options[ELEMENT_LI].type,
      markup: ["1.", "1)"],
      preFormat,
      format: (editor) => {
        toggleList(editor as SPEditor, { type: options[ELEMENT_OL].type })
      },
    },
    {
      type: options[MARK_BOLD].type,
      between: ["**", "**"],
      mode: "inline",
      insertTrigger: true,
    },
    {
      type: options[MARK_BOLD].type,
      between: ["__", "__"],
      mode: "inline",
      insertTrigger: true,
    },
    {
      type: options[MARK_ITALIC].type,
      between: ["*", "*"],
      mode: "inline",
      insertTrigger: true,
    },
    {
      type: options[MARK_ITALIC].type,
      between: ["_", "_"],
      mode: "inline",
      insertTrigger: true,
    },
    {
      type: options[MARK_CODE].type,
      between: ["`", "`"],
      mode: "inline",
      insertTrigger: true,
    },
    {
      type: options[MARK_STRIKETHROUGH].type,
      between: ["~~", "~~"],
      mode: "inline",
      insertTrigger: true,
    },
  ],
}
export const editableProps: EditableProps = {
  // placeholder: 'Enter some rich text…',
  spellCheck: false,
  autoFocus: true,
}

const createParagraph = (text: string | TNode<{}>[], mark?: string) => {
  let children: any
  if (typeof text === "string") {
    const leaf = { text }
    if (mark) {
      leaf[mark] = true
    }
    children = [leaf]
  } else {
    children = text
  }

  return {
    type: options[ELEMENT_PARAGRAPH].type,
    children,
  }
}
const createQuoteBlock = (children: TNode<{}>[]) => {
  return {
    type: options[ELEMENT_BLOCKQUOTE].type,
    children,
  }
}

const getNodesWithRandomId = (nodes: any[]) => {
  nodes.forEach((node) => {
    node.id = uuidv4()
  })
  return nodes
}

export const initialValue: any[] = [createParagraph("")]

export const getMessageBody = (message: PoolMessageBoardQuery_pool_messages_edges_node, isManager = false) => {
  let body: TNode<{}>[]
  if (message.deleteInformation) {
    const deletedByCBSAdmin = message.deleteInformation.deletedBy?.isCbsAdmin
    const deletedBy = deletedByCBSAdmin ? "CBS Admin" : "Pool Manager"
    if (isManager) {
      const deletedByData = message.deleteInformation.deletedBy?.preferredEntryName
      const { body: messageBody } = message
      let content: TNode<{}>[]
      try {
        content = JSON.parse(messageBody)
      } catch (error) {
        content = [createParagraph(messageBody)] as TNode<{}>[]
      }
      content = content.filter((x) => x.type !== ELEMENT_BLOCKQUOTE)
      body = getNodesWithRandomId([
        createQuoteBlock([createParagraph(getNodesWithRandomId(content))]),
        {
          type: options[ELEMENT_PARAGRAPH].type,
          children: [
            { text: `This message was deleted by "${deletedByData}" and is not visible to your pool.`, [options[MARK_SUPERSCRIPT].type]: true },
          ],
        },
      ])
    } else {
      body = [
        {
          children: [
            createQuoteBlock([
              {
                type: options[ELEMENT_PARAGRAPH].type,
                children: [{ text: "Deleted by: ", [options[MARK_SUBSCRIPT].type]: true }],
              },
              createParagraph(`${deletedBy}`, options[MARK_ITALIC].type),
            ]),
          ],
        },
      ] as TNode<{}>[]
    }
    return body
  }
  try {
    body = JSON.parse(message.body)
  } catch (error) {
    body = [createParagraph(message.body)] as TNode<{}>[]
  }
  return body
}
export const getQuote = (message: PoolMessageBoardQuery_pool_messages_edges_node) => {
  const { from, body, deleteInformation } = message
  const { firstName, lastName } = from?.userInfo || {}

  if (deleteInformation) {
    return initialValue
  }
  let content: TNode<{}>[]

  try {
    content = JSON.parse(body)
  } catch (error) {
    content = [createParagraph(body)] as TNode<{}>[]
  }

  content = content.filter((x) => x.type !== ELEMENT_BLOCKQUOTE)

  const result = getNodesWithRandomId([
    createQuoteBlock([
      createParagraph(`${firstName} ${lastName} said: `, options[MARK_SUBSCRIPT].type),
      createParagraph(getNodesWithRandomId(content)),
    ]),
    {
      type: options[ELEMENT_PARAGRAPH].type,
      children: [{ text: "" }],
    },
  ])
  return result
}

export const plugins: SlatePlugin[] = (() => {
  const plugins = [
    createReactPlugin(),
    createHistoryPlugin(),
    createParagraphPlugin(),
    createBlockquotePlugin(),
    createLinkPlugin(),
    createSubscriptPlugin(),
    createSuperscriptPlugin(),
    createListPlugin(),
    createCodeBlockPlugin(),
    createBoldPlugin(),
    createItalicPlugin(),
    createUnderlinePlugin(),
    createStrikethroughPlugin(),
    createAutoformatPlugin(optionsAutoformat),
    createSoftBreakPlugin(optionsSoftBreakPlugin),
    createExitBreakPlugin(optionsExitBreakPlugin),
    createTrailingBlockPlugin({
      type: options[ELEMENT_PARAGRAPH].type,
      level: 1,
    }),
  ]
  plugins.push(createDeserializeHTMLPlugin({ plugins }) as any)
  return plugins
})()

export const preserveClassNames = [
  SLATE_CLASSES.CBS_BOLD,
  SLATE_CLASSES.CBS_ITALIC,
  SLATE_CLASSES.CBS_OL,
  SLATE_CLASSES.CBS_P,
  SLATE_CLASSES.CBS_QUOTE,
  SLATE_CLASSES.CBS_TITLE_BOLD,
  SLATE_CLASSES.CBS_UL,
]

const mbStyles = {
  [SLATE_CLASSES.CBS_BOLD]: `style = "font-size: 14px; line-height: 16px; font-weight: 700; letter-spacing: -0.1px; text-align: left;"`,
  [SLATE_CLASSES.CBS_ITALIC]: `style = font-size: 14px; line-height: 16px; letter-spacing: -0.1px; text-align: left; font-style: italic;"`,
  [SLATE_CLASSES.CBS_OL]: `style = "background-color: transparent; color: #202121; list-style-type: decimal !important; padding-left: 40px; margin: 0;"`,
  [SLATE_CLASSES.CBS_UL]: `style = "background-color: transparent; color: #202121; list-style-type: square !important; padding-left: 40px; margin: 0;"`,
  [SLATE_CLASSES.CBS_P]: `style = "font-size: 14px; line-height: 16px; letter-spacing: -0.1px; text-align: left; margin: 0;"`,
  [SLATE_CLASSES.CBS_QUOTE]: `style = "font-size: 14px; line-height: 16px; letter-spacing: -0.1px; text-align: left; margin: 0 0 16px; border-left: 4px solid #0049CE; padding: 8px 16px 0; background-color: #D9DBDE"`,
  [SLATE_CLASSES.CBS_TITLE_BOLD]: `style = "font-size: 14px; line-height: 16px; letter-spacing: -0.1px; text-align: left; color: #0049CE; vertical-align: baseline; font-weight: 600;"`,
}

export const replaceMessageBoardStyleClass = (htmlContent: string): string => {
  let result = htmlContent
  preserveClassNames.forEach((x) => {
    const regex = new RegExp(`class="${x}"`, "ig")
    result = result.replace(regex, mbStyles[x])
  })
  return result
}
