import Button, { StyledButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
// import DeleteSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Delete"
import ChevLeftSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/ChevLeft"
import ReplySvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Reply"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import { fontWeight, newPalette, fontFamily, pxToRem, gridBreakPoints } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import React, { useCallback, useContext } from "react"
import styled from "styled-components/macro"
import { IMessageFormContext, MessageFormContext } from "./Context/MessageFormContext"

import { Redirect, useHistory } from "react-router-dom"
import MessageBody from "./MessageBody"
import { PoolMessageBoardQuery_pool_messages_edges_node } from "../../../../../__generated__/PoolMessageBoardQuery"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils"
import { withoutDomain } from "../../../../utils/url-utils"
import { trackActionByKey } from "./message-board-tracking"

const { breakpoints } = gridBreakPoints
// eslint-disable-next-line @typescript-eslint/ban-types
export interface ThreadDetailsProps {
  mkey: string
  messages: PoolMessageBoardQuery_pool_messages_edges_node[]
  totalCount: number
  goToThread: (key: string, value: string) => void
  isThreadDetails?: boolean
  topicsUrl: string
}

const ThreadDetailsWrap = styled.div`
  font-weight: ${fontWeight.bold};
  font-size: ${pxToRem(18)};
  display: flex;
  flex-direction: column;
  padding: 0;

  & > .thread-tile {
    background: ${newPalette.white};
    border: 1px solid ${newPalette.gray90};
    box-sizing: border-box;
    border-radius: 0.5rem;
    padding: 1.25rem 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;

    & > .subject {
      font-family: ${fontFamily.base};
      font-size: 18px;
      line-height: 1.5rem;
      letter-spacing: -0.1px;
      text-align: left;
      color: ${newPalette.gray20};
      font-weight: ${fontWeight.bold};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > .tile-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 1.5rem;
      margin-top: 1.25rem;
      border-top: 1px solid ${newPalette.gray90};

      & > .actions {
        display: flex;
        & > :first-child {
          margin-right: 1rem;
        }

        & > .mode-back {
          color: ${newPalette.gray20};
        }
      }
      & > .total {
        font-family: ${fontFamily.base};
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: -0.1px;
        text-align: right;
        color: ${newPalette.gray20};
        font-weight: ${fontWeight.regular};
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }

  & > .message-wrap {
    background: ${newPalette.white};
    border: 1px solid ${newPalette.gray90};
    box-sizing: border-box;
    border-radius: 0.5rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;

    & > .from-section {
    }
    & > .content-section {
    }
    & > .actions {
    }
  }
  & > .new-message-divider {
    margin-top: 1.5rem;

    & > span {
      font-family: ${fontFamily.base};
      position: relative;
      font-size: 14px;
      line-height: 1;
      letter-spacing: -0.1px;
      text-align: left;
      font-weight: ${fontWeight.semiBold};
      display: flex;
      color: ${newPalette.lightBlue2};
    }
    & > span:after {
      content: "";
      position: absolute;
      height: 1px;
      display: flex;
      flex: 1;
      margin-left: 1rem;
      background-color: ${newPalette.gray90};
      top: 50%;
      left: 5rem;
      width: calc(100% - 5rem);
    }
  }

  & > .bottom-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.25rem;
    border: 1px solid ${newPalette.gray90};
    background: ${newPalette.white};
    padding: 1.5rem;
    border-radius: 0.5rem;

    & > .actions {
      display: flex;
      & > :first-child {
        margin-right: 1rem;
      }

      & > .mode-back {
        color: ${newPalette.gray20};
      }
    }
    & > .total {
      font-family: ${fontFamily.base};
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: -0.1px;
      text-align: right;
      color: ${newPalette.gray20};
      font-weight: ${fontWeight.regular};
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  @media (max-width: ${breakpoints.sm - 1}px) {
    & > .thread-tile {
      border-radius: 0;
    }
  }
`

const ThreadDetails = (props: ThreadDetailsProps) => {
  const { messages, topicsUrl, totalCount } = props
  const history = useHistory()
  const messageFormContext: IMessageFormContext = useContext(MessageFormContext)
  const { rootMessage, currentPlayer } = messageFormContext
  const { canPostMessages } = currentPlayer || emptyObject

  const goToTopics = useCallback(() => {
    history.push(withoutDomain(topicsUrl))
  }, [topicsUrl, history])

  if (!rootMessage) {
    return <Redirect to={topicsUrl} />
  }
  const { subject } = rootMessage
  const handleReplyToTopic = () => {
    messageFormContext.showMessageForm(true)
    trackActionByKey("reply-to-topic")
  }
  return (
    <ThreadDetailsWrap>
      <div className="thread-tile">
        <span className="subject">{subject}</span>
        <div className="tile-bottom">
          <div className="actions">
            <StyledButton onClick={goToTopics} className="variant--secondary size--md mode-back">
              <ListItemIcon size="md" alignment="left">
                <ChevLeftSvg />
              </ListItemIcon>
              Back
            </StyledButton>
            {canPostMessages && (
              <Button onClick={handleReplyToTopic}>
                <ListItemIcon size="md" alignment="left">
                  <ReplySvg />
                </ListItemIcon>
                Reply To Topic
              </Button>
            )}
          </div>
          <span className="total">{`${totalCount === 1 ? "Reply" : "Replies"}: ${totalCount}`}</span>
        </div>
      </div>
      <MessageBody key={`root-${rootMessage.id}`} message={rootMessage} showActions={false} />
      {messages.map((message) => {
        return <MessageBody key={message.id} message={message} />
      })}
      {messages?.length > 2 && (
        <div className="bottom-actions">
          <div className="actions">
            <StyledButton onClick={goToTopics} className="variant--secondary size--md mode-back">
              <ListItemIcon size="md" alignment="left">
                <ChevLeftSvg />
              </ListItemIcon>
              Back
            </StyledButton>
            {canPostMessages && (
              <Button onClick={handleReplyToTopic}>
                <ListItemIcon size="md" alignment="left">
                  <ReplySvg />
                </ListItemIcon>
                Reply To Topic
              </Button>
            )}
          </div>
        </div>
      )}
    </ThreadDetailsWrap>
  )
}

export default ThreadDetails
