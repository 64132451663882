import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import {
  fontWeight,
  newPalette,
  fontFamily,
  gridBreakPoints,
  buildClassNames as cx,
} from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { IMessageFormContext, MessageFormContext } from "./Context/MessageFormContext"
import Avatar from "@cbs-sports/sports-shared-client/build/cjs/components/Avatar"
import { getDateDiff } from "../../../../utils/data-utils"
import MessageSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Message"
import CheckBoxFilledSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/CheckBoxFilled"
import CheckBoxSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/CheckBox"
import PoolDataContext, { PoolDataContextType } from "../../../../Contexts/PoolDataContext"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { PoolMessageBoardQuery_pool_messages_edges_node } from "../../../../../__generated__/PoolMessageBoardQuery"
import DotSvg from "../../../../components/icons/Dot"

const { breakpoints } = gridBreakPoints

const selectedColor = "#E5F4FF"
const hoverColor = "#F2F9FF"
interface ThreadItemProps {
  message: PoolMessageBoardQuery_pool_messages_edges_node
  isSelected?: boolean
  onToggleSelect: (messageId: string) => void
  goToThread: (key: string, messageId: string) => void
}

const ThreadItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  height: 5.5rem;
  box-sizing: border-box;
  border-top: 1px solid ${newPalette.gray90};
  padding: 1rem 1.5rem;
  align-items: center;

  & > .left-side {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > .left-side-top {
      display: flex;
      position: relative;
      max-width: 100%;

      & > .message-info {
        display: flex;
        flex-direction: column;
        margin-left: 0.75rem;
        justify-content: center;
        cursor: pointer;
        max-width: calc(100% - 4rem);

        & > .subject {
          font-family: ${fontFamily.base};
          font-size: 14px;
          line-height: 1.25rem;
          letter-spacing: -0.1px;
          text-align: left;
          color: ${newPalette.gray40};
          font-weight: ${fontWeight.bold};
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        & > .from {
          font-family: ${fontFamily.base};
          font-size: 0.75rem;
          line-height: 1rem;
          letter-spacing: -0.1px;
          display: flex;
          text-align: left;
          color: ${newPalette.gray40};
          font-weight: ${fontWeight.medium};

          & > .time {
            display: flex;
            align-items: center;

            & > svg {
              width: 0.25rem;
              height: 0.25rem;
              margin: 0 0.25rem;
            }
          }
        }
      }
    }

    & > .left-side-top.with-new-messages:before {
      content: "";
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 100%;
      left: -1rem;
      top: 1rem;
      background-color: ${newPalette.lightBlue2};
    }
    & > .left-side-top.with-new-messages {
      & > .message-info {
        & > .subject {
          color: ${newPalette.gray20};
        }
        & > .from {
          color: ${newPalette.gray20};
        }
      }
    }

    & > .left-side-bottom {
      display: flex;
      display: flex;
      flex-direction: row;
      margin-left: 3.25rem;
      align-items: center;

      & > .to {
        font-family: ${fontFamily.base};
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: -0.1px;
        text-align: left;
        color: ${newPalette.gray40};
        font-weight: ${fontWeight.regular};
      }
      & > .messages-info {
        font-family: ${fontFamily.base};
        font-weight: ${fontWeight.regular};
        position: relative;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: -0.1px;
        text-align: left;
        color: ${newPalette.gray40};
        margin-left: 1rem;
        display: flex;

        & > .message-time {
          display: flex;
          align-items: center;

          & > svg {
            width: 0.25rem;
            height: 0.25rem;
            margin: 0 0.25rem;
          }
        }
      }
      & > .messages-info.with-message {
        color: ${newPalette.lightBlue2};
        font-weight: ${fontWeight.medium};
      }
      & > .messages-info:before {
        content: "";
        position: absolute;
        width: 1px;
        height: 0.75rem;
        left: -0.5rem;
        top: 2px;
        background-color: ${newPalette.gray90};
      }
    }
  }
  & > .left-side.with-actions {
    max-width: calc(100% - 6.25rem); // (count|select)
  }

  & > .thread-info {
    display: flex;
    box-sizing: border-box;
    align-items: center;

    & > .message-count {
      display: flex;
      align-items: center;
      width: 3rem;
      color: ${newPalette.gray50};
      font-family: ${fontFamily.base};
      font-weight: ${fontWeight.medium};
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0px;
      text-align: left;

      svg {
        width: 1.25rem;
        height: 1.25rem;
        color: inherit;
        margin-right: 0.5rem;
      }
    }
  }

  & > .message-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    box-sizing: border-box;
    border-left: 1px solid ${newPalette.gray90};
    justify-content: flex-end;
    margin-left: 1rem;

    & > .check {
      width: 1.25rem;
      height: 1.25rem;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
        color: ${newPalette.gray50};
      }
    }
  }

  &.is-selected {
    background-color: ${selectedColor};

    & > .message-actions {
      & > .check {
        svg {
          color: ${newPalette.lightBlue2};
        }
      }
    }
  }

  @media (max-width: ${breakpoints.sm - 1}px) {
    & > .left-side.with-actions {
      max-width: 100%; // (view|count|select)
    }
    & > .message-actions,
    & > .thread-info {
      display: none;
    }
  }

  @media (hover: hover) {
    :hover:not(.is-selected) {
      background-color: ${hoverColor};
    }
  }
`

const SentAt = ({ sentAt }: { sentAt: number }) => {
  const intervalID = useRef<number | null>(null)
  const [time, setTime] = useState<string>("")
  useEffect(() => {
    setTime(getDateDiff(sentAt))
    intervalID.current = setInterval(() => setTime(getDateDiff(sentAt)), 60000)
    return () => {
      if (intervalID.current) {
        clearInterval(intervalID.current)
      }
    }
  }, [sentAt])

  return (
    <span className="message-time">
      <DotSvg />
      {time}
    </span>
  )
}

const ThreadItem = ({ message, isSelected, onToggleSelect, goToThread }: ThreadItemProps) => {
  const { sentAt, subject, replyCount, lastReply, from } = message
  const { avatarUrl, userInfo } = from || {}
  const { firstName, lastName } = userInfo || {}
  const messageFormContext: IMessageFormContext = useContext(MessageFormContext)
  const poolData: PoolDataContextType = useContext(PoolDataContext)
  const { currentUser } = messageFormContext
  const { hasManagerRole } = poolData || emptyObject
  const { isCbsAdmin } = currentUser || ({} as never)

  const showActions = isCbsAdmin || hasManagerRole

  const handleSelect = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    onToggleSelect(message.id)
  }
  const fullName = `${firstName} ${lastName}`

  return (
    <ThreadItemWrap
      className={cx({
        "is-selected": isSelected,
      })}
      onClick={() => goToThread("rootMessageId", message.id)}
    >
      <div className={cx({ "left-side": true, "with-actions": showActions })}>
        <div className="left-side-top">
          <Avatar size={40} alt="" src={avatarUrl || undefined} name={fullName} />
          <div className="message-info">
            <span className="subject">{subject}</span>
            <span className="from">
              {fullName}
              <span className="time">
                <DotSvg />
                {getDateDiff(sentAt)}
              </span>
            </span>
          </div>
        </div>
        <div className="left-side-bottom">
          <span className="to">To: Entire Pool</span>
          {!!lastReply && (
            <span className="messages-info">
              Last Reply
              <SentAt sentAt={lastReply.sentAt} />
            </span>
          )}
        </div>
      </div>
      <div className="thread-info">
        <div className="message-count">
          <MessageSvg />
          {replyCount}
        </div>
      </div>
      {showActions && (
        <div className="message-actions">
          <div className="check" onClick={handleSelect}>
            {isSelected && <CheckBoxFilledSvg />}
            {!isSelected && <CheckBoxSvg />}
          </div>
        </div>
      )}
    </ThreadItemWrap>
  )
}
export default ThreadItem
