import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import PlusSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Plus"
import CheckBoxFilledSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/CheckBoxFilled"
import CheckBoxSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/CheckBox"
import PartialCheckSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/PartialCheck"
import DeleteSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Delete"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import {
  fontWeight,
  newPalette,
  fontFamily,
  pxToRem,
  buildClassNames as cx,
  gridBreakPoints,
} from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import React, { useContext, useState } from "react"
import styled from "styled-components/macro"
import { IMessageFormContext, MessageFormContext } from "./Context/MessageFormContext"
import ThreadItem from "./ThreadItem"
import ThreadDetails, { ThreadDetailsProps } from "./ThreadDetails"

import PoolDataContext, { PoolDataContextType } from "../../../../Contexts/PoolDataContext"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import GenericConfirmationModal from "../../components/EntryModals/GenericConfirmationModal"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast/toast"
import { trackActionByKey } from "./message-board-tracking"
const { breakpoints } = gridBreakPoints
type IMessagesContainer = ThreadDetailsProps

const Thread = styled.div`
  font-weight: ${fontWeight.bold};
  font-size: ${pxToRem(18)};
  display: flex;
  flex-direction: column;
  padding: 0;

  & > .threads-header {
    padding: 0 1.5rem;

    & > .title {
      font-family: ${fontFamily.base};
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.5rem;
      letter-spacing: 0px;
      text-align: left;
      color: ${newPalette.gray20};
      font-weight: ${fontWeight.bold};
      display: flex;
      text-transform: uppercase;
    }
    & > .subtitle {
      font-family: ${fontFamily.base};
      font-size: 14px;
      line-height: 1.25rem;
      letter-spacing: -0.1px;
      text-align: left;
      font-weight: ${fontWeight.regular};
      color: ${newPalette.gray40};
    }
    & > .actions {
      display: flex;
      flex-direction: row;
      padding: 1.5rem 0;
      box-sizing: border-box;
      justify-content: space-between;

      & > .admin-actions {
        display: flex;
        flex-direction: row;
        align-items: center;

        & > .separator {
          width: 1px;
          background-color: ${newPalette.gray90};
          height: 2.25rem;
          margin: 0 1rem;
        }

        & > .check-options {
          width: 1.25rem;
          height: 1.25rem;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
            color: ${newPalette.lightBlue2};
          }
        }
        & > .check-options.none {
          svg {
            width: 100%;
            height: 100%;
            color: ${newPalette.gray50};
          }
        }
      }
    }
    & > .actions.silenced {
      justify-content: flex-end;
    }
    & > .list-title {
      padding-bottom: 1rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > .title {
        font-family: ${fontFamily.base};
        font-size: 1rem;
        line-height: 1;
        letter-spacing: -0.1px;
        text-align: left;
        color: ${newPalette.gray20};
        font-weight: ${fontWeight.bold};
        display: flex;
      }
      & > .total {
        font-family: ${fontFamily.base};
        font-size: 0.75rem;
        line-height: 14px;
        letter-spacing: -0.1px;
        text-align: right;
        color: ${newPalette.gray20};
        font-weight: ${fontWeight.regular};
        display: flex;
      }
    }
  }
  & > .no-messages {
    display: flex;
    flex: 1;
    align-items: center;
    box-sizing: border-box;
    padding: 2rem 1.5rem;
    border-top: 1px solid ${newPalette.gray90};
    font-family: ${fontFamily.base};
    font-size: 14px;
    line-height: 1;
    letter-spacing: -0.1px;
    justify-content: center;
    font-weight: ${fontWeight.bold};
    color: ${newPalette.gray20};
  }

  & > .selected-conversations {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 3rem;
    background-color: ${newPalette.gray97};
    border-top: 1px solid ${newPalette.gray90};

    & > span {
      font-family: ${fontFamily.base};
      font-size: 14px;
      line-height: 1rem;
      letter-spacing: -0.1px;
      color: ${newPalette.gray20};
      font-weight: ${fontWeight.regular};

      & > strong {
        font-weight: ${fontWeight.bold};
      }
    }
  }

  @media (max-width: ${breakpoints.sm - 1}px) {
    & > .threads-header {
      & > .actions {
        & > .admin-actions {
          display: none;
        }
      }
    }
  }
`
const MessagesContainer = (props: IMessagesContainer) => {
  const { messages, isThreadDetails = false, goToThread, totalCount } = props
  const messageFormContext: IMessageFormContext = useContext(MessageFormContext)
  const poolData: PoolDataContextType = useContext(PoolDataContext)
  const [selectedMessages, setSelectedMessages] = useState<string[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { currentUser, currentPlayer, removeMessageMutation } = messageFormContext
  const { hasManagerRole, poolId } = poolData || emptyObject
  const { isCbsAdmin } = currentUser || emptyObject
  const { canPostMessages } = currentPlayer || emptyObject

  const showActions = isCbsAdmin || hasManagerRole

  const toggleSelect = (messageId: string) => {
    const isSelected = selectedMessages.includes(messageId)
    if (isSelected) {
      const newSelection = selectedMessages.filter((x) => x !== messageId)
      setSelectedMessages(newSelection)
    } else {
      setSelectedMessages([...selectedMessages, messageId])
    }
  }
  const toggleAll = () => {
    const isNotEmpty = selectedMessages.length > 0
    if (isNotEmpty) {
      setSelectedMessages([])
    } else {
      const newSelection = messages.map((x) => x.id)
      setSelectedMessages(newSelection)
    }
  }

  const handleDeleteMessages = () => {
    trackActionByKey("delete-topic")
    removeMessageMutation({
      variables: {
        messageIds: selectedMessages,
        reason: "OTHER",
        poolId,
      },
    })
      .then((res) => {
        const total = res.data?.removeMessages?.length
        const msg = (total || 0) > 1 ? `${total} conversations deleted` : "Conversation deleted"
        toast.snackbar(msg)
      })
      .finally(() => {
        setShowDeleteModal(false)
        setSelectedMessages([])
      })
  }

  if (isThreadDetails) {
    return <ThreadDetails {...props} />
  }

  const noMessages = !messages?.length
  const showInfo = selectedMessages.length > 0 || !canPostMessages
  const onStartNewTopicClick = () => {
    messageFormContext.showMessageForm()
    trackActionByKey("start-new-topic")
  }

  const allMessagesSelected = selectedMessages.length === messages.length

  return (
    <Thread>
      <div className="threads-header">
        <span className="title">MESSAGE BOARD</span>
        <span className="subtitle">The Message Board is for pool discussion. All postings will be CC'd to your pool’s email list.</span>
        <div
          className={cx({
            actions: true,
            silenced: !canPostMessages,
          })}
        >
          {canPostMessages && (
            <Button onClick={onStartNewTopicClick} data-cy="start-new-topic">
              <ListItemIcon size="md" alignment="left">
                <PlusSvg />
              </ListItemIcon>
              Start New Topic
            </Button>
          )}
          {showActions && messages.length > 0 && (
            <div className="admin-actions">
              {selectedMessages.length > 0 && (
                <Button variant="secondary" onClick={() => setShowDeleteModal(true)}>
                  <ListItemIcon size="md" alignment="left">
                    <DeleteSvg />
                  </ListItemIcon>
                  Delete
                </Button>
              )}
              <div className="separator" />
              <div
                className={cx({
                  "check-options": true,
                  all: selectedMessages.length === messages.length,
                  none: selectedMessages.length === 0,
                  partial: selectedMessages.length > 0 && selectedMessages.length !== messages.length,
                })}
                onClick={toggleAll}
              >
                {selectedMessages.length === messages.length && <CheckBoxFilledSvg />}
                {selectedMessages.length === 0 && <CheckBoxSvg />}
                {selectedMessages.length > 0 && selectedMessages.length !== messages.length && <PartialCheckSvg />}
              </div>
            </div>
          )}
        </div>
        <div className="list-title">
          <span className="title">Topics</span>
          {messages.length > 0 && <span className="total">{`Total: ${totalCount}`}</span>}
        </div>
      </div>
      {noMessages && <span className="no-messages">Your pool message board is empty.</span>}
      {showInfo && (
        <div className="selected-conversations">
          {selectedMessages?.length > 0 && (
            <span>
              {allMessagesSelected ? "All" : ""}
              <strong>{` ${allMessagesSelected && selectedMessages?.length === 1 ? "" : selectedMessages?.length} conversation${
                selectedMessages.length > 1 || allMessagesSelected ? "s" : ""
              } `}</strong>
              {`on this page ${selectedMessages.length > 1 || allMessagesSelected ? "are" : "is"} selected.`}
            </span>
          )}
          {!canPostMessages && <span>Your Message Board privileges have been muted by the Pool Manager.</span>}
        </div>
      )}

      {messages.map((message) => {
        return (
          <ThreadItem
            key={`message-key-${message.id}`}
            message={message}
            onToggleSelect={toggleSelect}
            goToThread={goToThread}
            isSelected={selectedMessages.includes(message.id)}
          />
        )
      })}
      <GenericConfirmationModal
        isOpen={showDeleteModal}
        onConfirm={handleDeleteMessages}
        onClose={() => setShowDeleteModal(false)}
        title="Delete conversations?"
        message="All messages in a conversation will be deleted."
        ctaButtonLabel="Delete"
      />
    </Thread>
  )
}

export default MessagesContainer
